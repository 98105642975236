import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import rizoom from '../../assets/images/rizoom.svg';

const LogoStyles = styled.div`
  padding: 30px 0;
  @media screen and (min-width: 64em) {
    padding: 100px 0;
  }
  img {
    @media screen and (max-width: 39.9375em) {
      width: 60%;
    }
    width: 296px;
  }
`;

export default function CoverLogo() {
  return (
    <div>
      <LogoStyles>
        <Link to="/">
          <img src={rizoom} width="296" alt="Rizoom" />
        </Link>
      </LogoStyles>
    </div>
  );
}
