import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import PortableText from '@sanity/block-content-to-react';
import { motion } from 'framer-motion';
import Grid from '../components/Grid';
import CoverLogo from '../components/covers/CoverLogo';
import TransformNav from '../components/TransformNav';

import PageTransition from '../components/PageTransition';
import SEO from '../components/Seo';

const ArtistListStyles = styled.ul`
  list-style: none;
  grid-column: span 12;
  @media screen and (min-width: 40em) {
    grid-column: 1 / span 6;
  }
  color: #aaaaaa;
  cursor: pointer;
  padding-left: 0;
  padding-bottom: 30px;
  @media screen and (min-width: 64em) {
    padding-bottom: var(--blockpadding);
  }
  li {
    transition: 0.2s all ease-in-out;
    line-height: 27px;
    &:hover {
      color: #000;
    }
  }
  li.is-active {
    color: #000;
  }
`;

const ArtistDetailStyles = styled.div`
  grid-column: span 12;
  @media screen and (min-width: 40em) {
    grid-column: span 6;
  }
  padding-bottom: var(--blockpadding);
  position: relative;
  img,
  picture source,
  picture,
  picture {
    height: 211px;
    max-height: 211px;
  }
  .profile {
    gap: 20px;
    display: flex;
    margin-bottom: 30px;

    .image {
      flex: 1;
      height: 200px;
    }
    .text-holder {
      h2 {
        font-size: 26px;
        line-height: 33px;
      }
      p {
        color: rgba(0, 0, 0, 0.5);
      }
      flex: 1;
    }
  }
  [data-text] {
    position: absolute;
    top: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    transition: 0.1s all 0.2s ease-in-out;
    p {
      height: 0;
    }
    &.is-active {
      max-height: auto;
      opacity: 1;
      visibility: visible;
    }
  }
`;

const CoverLogoStyles = styled.div`
  grid-column: span 12;
`;

export default function Artists({ data }) {
  const artists = data?.artists.nodes;
  let artistsList;
  const [activeArtist, setActiveArtist] = useState([artists[0]]);
  const [activePage, setActivePage] = useState(false);

  const artistList = useRef();

  function viewArtist(e, id, artists) {
    artistsList = artistList.current.querySelectorAll('li');
    artists.forEach((artist) =>
      id === artist._id ? setActiveArtist(artist) : null
    );
    artistsList.forEach((artist) => artist.classList.remove('is-active'));
    e.currentTarget.classList.add('is-active');
  }

  useEffect(() => {
    setTimeout(() => {
      setActivePage(true);
    }, 1000);
    artistsList = artistList.current.querySelectorAll('li');
    artistsList[0]?.classList.add('is-active');
    setActiveArtist(artists[0]);
  }, []);

  return (
    <>
      <SEO title={data.page.seo_title} desc={data.page.seo_description} />
      <PageTransition>
        <Grid>
          <TransformNav />
          <CoverLogoStyles>
            <CoverLogo />
          </CoverLogoStyles>
          <ArtistListStyles ref={artistList}>
            {artists?.map((artist, i) => (
              <React.Fragment key={i}>
                {artist.actueel ? (
                  <li
                    data-artist={artist._id}
                    onClick={(e) => viewArtist(e, artist._id, artists)}
                  >
                    {artist.name}
                  </li>
                ) : null}
              </React.Fragment>
            ))}
          </ArtistListStyles>
          <ArtistDetailStyles>
            <div className="profile">
              <div className="image">
                {activeArtist?.image ? (
                  <Link to={`/artist/${activeArtist?.slug?.current}`}>
                    <Img
                      fluid={activeArtist?.image?.asset?.fluid}
                      alt={activeArtist.name}
                    />
                  </Link>
                ) : null}
              </div>
              <div className="text-holder">
                <h2>{activeArtist.name}</h2>
                <p>
                  {activeArtist?.discipline?.length
                    ? activeArtist.discipline.map((x, i) => (
                        <React.Fragment key={i}>
                          {x.name}
                          <br />
                        </React.Fragment>
                      ))
                    : null}
                </p>
                {activeArtist?.slug ? (
                  <Link
                    to={`/artist/${activeArtist?.slug?.current}`}
                    className="button"
                  >
                    Lees meer
                  </Link>
                ) : null}
              </div>
            </div>
            {activeArtist.artistIntro}
          </ArtistDetailStyles>
        </Grid>
      </PageTransition>
    </>
  );
}

export const query = graphql`
  query Actueel {
    artists: allSanityArtist {
      nodes {
        _id
        name
        _rawRichText
        artistIntro
        actueel
        discipline {
          name
        }
        slug {
          current
        }
        image {
          asset {
            fluid(maxWidth: 2100) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    page: sanityPage(slug: { current: { eq: "actueel" } }) {
      name
      seo_title
      seo_description
    }
    footer: allSanitySiteSettings {
      edges {
        node {
          address_1
          address_2
          address_3
          facebook_url
          email
          instagram_url
          phone
          studio_title
          title
          title_1
          title_2
          vimeo_url
        }
      }
    }
  }
`;
